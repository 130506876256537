<template>
  <div class="col-12">
    <div class="row h-120">
      <div class="col-2 max-1260 d-flex align-items-center p-0 mx-2">
        <img v-if="data && data.media_gallery_entries && data.media_gallery_entries.length > 0" class="img-fluid" :src="productImageUrl(data.media_gallery_entries[0].file)" alt="Product Image">
        <img v-else class="img-fluid" :src="productImageUrl('/placeholder/websites/1/img_placeholder_base.jpg')" alt="Product Image">
      </div>
      <div class="col d-flex flex-column h-100 ps-0">
        <div class="row align-items-center">
          <div class="col d-flex align-items-center fw-bold lh-sm">
            <router-link :to="`/products/${product.product_id}`" target="_blank" rel="noopener noreferrer" class="text-dark text-decoration-none">
              {{ product.name }}
              {{ productParfum ?? null }}
              {{ productColor ?? null }}
              {{ productSize ? ' - Taille : ' + productSize : null }}
            </router-link>
          </div>
          <div class="col-2 square-64 d-flex justify-content-center align-items-center fw-bold border" :class="colorShipped(item)">
            {{ item.qty_shipped }}/{{ item.qty_ordered }}
          </div>
        </div>
        <div class="row flex-grow-1 align-items-center">
          <div class="col-9">
            <div class="row">
              <div class="col-7">
                <div class="row d-flex flex-column">
                  <div class="col fw-light fs-sm">SKU : {{ item.sku }}</div>
                  <div class="col fw-light fs-sm">Fournisseur : {{ productSupplier }}</div>
                  <div class="col fw-light fs-sm">Stock logistique : {{ stock?.quantity }}</div>
                </div>
              </div>
              <div class="col-5">
                <div class="row d-flex flex-column">
                  <div class="col fw-light fs-sm"><br></div>
                  <div v-if="item.qty_invoiced > 0" class="col fw-light fs-sm">Facturé : {{ item.qty_invoiced }}</div>
                  <div v-else-if="item.qty_ordered > 0" class="col fw-light fs-sm">Commandé : {{ item.qty_ordered }}</div>
                  <div v-if="item.qty_refunded > 0" class="col fw-light fs-sm">Remboursé : {{ item.qty_refunded }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="row row-cols-1 align-items-center">
              <div v-if="item.original_price * item.qty_invoiced != item.row_total_incl_tax" class="col text-end text-decoration-line-through">
                {{ item.qty_invoiced ? round(item.original_price * item.qty_invoiced, 2) : round(item.original_price * item.qty_ordered, 2) }} €
              </div>
              <div class="col text-end fw-bold">
                {{ round(item.row_total_incl_tax, 2) }} €
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import { round, productImageUrl } from '@/utils/tools';
import productService from '@/services/productService';

export default {
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: null,
      stock: null,
    };
  },
  computed: {
    ...mapGetters('attributes', ['attributes']),
    item() {
      return this.product.parent_item || this.product;
    },
    productColor() {
      const colorAttribute = this.data?.custom_attributes?.find(attr => attr.attribute_code === 'couleur')?.value;
      return this.attributes.colors.find(option => option.value === colorAttribute)?.label;
    },
    productSize() {
      const sizeAttribute = this.data?.custom_attributes?.find(attr => attr.attribute_code === 'taille')?.value;
      return this.attributes.sizes.find(option => option.value === sizeAttribute)?.label;
    },
    productSupplier() {
      const supplierAttribute = this.data?.custom_attributes?.find(attr => attr.attribute_code === 'nom_fournisseur')?.value;
      return this.attributes.suppliers.find(option => option.value === supplierAttribute)?.label;
    },
    productParfum() {
      const parfumAttribute = this.data?.custom_attributes?.find(attr => attr.attribute_code === 'parfum')?.value;
      return this.attributes.parfumes.find(option => option.value === parfumAttribute)?.label;
    },
  },
  methods: {
    round,
    productImageUrl,
    colorShipped(item) {
      if (item.qty_refunded > 0 && item.qty_shipped < item.qty_ordered) {
        return 'border-secondary bg-secondary-subtle';
      } else if (item.qty_invoiced === 0 && item.qty_invoiced < item.qty_ordered) {
        return 'border-secondary bg-secondary-subtle';
      } else if (item.qty_shipped < item.qty_ordered) {
        return 'border-warning bg-warning-subtle';
      } else if (item.qty_shipped === 0 && item.qty_ordered === 0) {
        return 'border-secondary bg-secondary-subtle';
      } else if (item.qty_shipped === item.qty_ordered) {
        return 'border-success bg-success-subtle';
      } else if (item.qty_shipped > item.qty_ordered) {
        return 'border-danger bg-danger-subtle';
      }
    },
  },
  async mounted() {
    this.data = await productService.getProductBySku(this.product.sku);
    this.stock = await productService.getProductStockInStore(this.data.id, 'default');
  },
};
</script>


<style scoped>
@media (max-width: 1260px) {
  .max-1260 {
    display: none;
  }
}
</style>