<template>
  <div class="col d-flex flex-column h-100">
    <div class="row flex-grow-1 overflow-auto">
      
      <template v-if="error">{{ error }}</template>
      <template v-else-if="loading"><loader/></template>
      <template v-else>
        
        <draggable
          class="row row-cols-3 flex-grow-1 overflow-y-auto justify-content-center align-items-center align-content-center"
          :list="refund.products"
          :group="{ name: 'refund-products', pull: true, put: ['products'] }"
          :multi-drag="false"
          selected-class="selected"
          :sort="false"
          @add="addProduct">

          <template v-slot:item="{ element, index }">
            <article v-if="element" class="col-10 m-1" :key="index">
              <refund-product :product="element" />
            </article>
          </template>

        </draggable>

      </template>
    </div>

    <div class="row align-items-center border-top py-1 ">
      <div class="col">
        <span v-if="refund.id_refund"><strong>Demande de remboursement #{{ refund.id_refund }}</strong> créé le {{ formatDate(refund.date_add, false).full }}</span>
        <span v-else ><strong>Nouvelle demande de remboursement</strong></span>
      </div>
      <div class="col-auto ms-auto">
        <button v-if="cookie.user.id_role < 3" class="btn btn-sm btn-success me-2" @click="validRefundRequest">Valider le statut</button>
        <button v-if="refund.id_refund" class="btn btn-sm btn-danger" @click="closeWorkplan">Fermer</button>
        <button v-else class="btn btn-sm btn-danger" @click="closeWorkplan">Annuler</button>
      </div>
    </div>

    <div class="row border-top py-3 bg-light">
      <div class="col-12">
        <div class="row h-128 flex-nowrap overflow-x-auto" @wheel="horizontalScroll">
          
          <action-card 
            v-if="displayRefundShipping" 
            :title="'Frais de port'" 
            :modal="'editTrackingModal'">

            <template #content>
              <div class="input-group input-group-sm mb-2">
                <input 
                v-model="shippingAmount" 
                class="form-control form-control-sm" 
                :disabled="refund.id_last_state > 1"
                @input="validateNumber" 
                placeholder="Montant">
                <span class="input-group-text">€</span>
              </div>
              <select v-model="id_reason_shipping" class="form-select form-select-sm" aria-label="Default select example" :disabled="refund.id_last_state > 1">
                <option v-for="(reason, index) in states.refunds.reasons.shipping" :key="index" :value="reason.id_refund_reason">{{ reason.name }}</option>
                <option value="false">Ne pas rembourser</option>
              </select>
            </template>
          </action-card>

          <action-card v-else-if="order.base_shipping_incl_tax" :title="'Frais de port'">
            <template #content>
              <button class="btn btn-sm btn-primary" @click="displayRefund('shipping')" :disabled="refund.id_last_state > 1">Rembourser</button>
            </template>
          </action-card>
          
          <action-card 
            v-if="displayRefundFees" 
            :title="'Pack Sérénité'">

            <template #content>
              <div class="input-group input-group-sm mb-2">
                <input 
                v-model="feesAmount" 
                type="text"
                class="form-control form-control-sm" 
                :disabled="refund.id_last_state > 1"
                @input="validateNumber" 
                placeholder="Montant">
                <span class="input-group-text">€</span>
              </div>
              <select v-model="id_reason_fees" class="form-select form-select-sm" aria-label="Default select example" :disabled="refund.id_last_state > 1">
                <option v-for="(reason, index) in states.refunds.reasons.fees" :key="index" :value="reason.id_refund_reason">{{ reason.name }}</option>
                <option value="false">Ne pas rembourser</option>
              </select>
            </template>
          </action-card>

          <action-card v-else-if="order.extension_attributes.base_fee_incl_tax" :title="'Pack Sérénité'">
            <template #content >
              <button class="btn btn-sm btn-primary" @click="displayRefund('fees')" :disabled="refund.id_last_state > 1">Rembourser</button>
            </template>
          </action-card>

          <action-card v-if="displayRefundGift" :title="'Papier cadeau'">
          <template #content>
            <div class="input-group input-group-sm mb-2">
              <input 
                v-model.number="giftAmount" 
                type="text" 
                class="form-control form-control-sm" 
                :disabled="refund.id_last_state > 1" 
                @input="validateNumber" 
                placeholder="Montant">
              <span class="input-group-text">€</span>
            </div>
            <select v-model="id_reason_gift" class="form-select form-select-sm" aria-label="Default select example">
              <option v-for="(reason, index) in states.refunds.reasons.gift" :key="index" :value="reason.id_refund_reason">{{ reason.name }}</option>
              <option value="false">Ne pas rembourser</option>
            </select>
          </template>
        </action-card>

          <action-card v-else-if="order.extension_attributes.gift_wrap_incl_tax" :title="'Pap. cadeau'">
            <template #content >
              <button class="btn btn-sm btn-primary" @click="displayRefund('gift')" :disabled="refund.id_last_state > 1">Rembourser</button>
            </template>
          </action-card>

          <action-card :title="'Total calculé'" class="me-auto">
            <template #content>
              <div class="input-group input-group-sm mb-2">
                <input v-model="grandTotal" type="number" class="form-control form-control-sm" readonly disabled>
                <span class="input-group-text">€</span>
              </div>
              <button v-if="!displayAjustement" class="col-12 btn btn-primary btn-sm" @click="displayAjustement = !displayAjustement" :disabled="refund.id_last_state > 1" >Ajuster le montant</button>
              <div v-if="displayAjustement" class="mt-2">
                <div class="input-group input-group-sm">
                  <input v-model.number="ajustement" type="number" class="form-control form-control-sm" placeholder="Valeur d'ajustement">
                  <span class="input-group-text" @click="isPercentage = !isPercentage">{{ isPercentage ? '%' : '€' }}</span>
                </div>
              </div>
            </template>
          </action-card>

          <!-- Boutons d'action -->
          <action-button v-if="displayCreateRefundRequestButton" :icon="'bi-cloud-upload'" :text="'Demander\nun remb.'" :color="'white'" :type="'primary'" @click="createRefundRequest(refund)" />
          <action-button v-if="displayCancelRefundButton" :icon="'bi-x'" :text="'Annuler\nle rembours.'" :color="'white'" :type="'danger'" @click="cancelRefundRequest(refund)" />
          <action-button v-if="displayUpdateRefundRequestButton" :icon="'bi-cloud-upload'" :text="'Mettre à jour\nle rembours.'" :color="'white'" :type="'primary'" @click="updateRefundRequest(refund)" />
          <action-button v-if="displayValidateRefundButton" :icon="'bi-check'" :text="'Valider\nle rembours.'" :color="'white'" :type="'success'" @click="createCreditMemo(refund)" />
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { round, capitalizeEachWord, formatDate, horizontalScroll } from '@/utils/tools';
import RefundProduct from '@/components/order/OrderRefundProduct';
import ActionCard from '@/components/common/ActionCard';
import ActionButton from '@/components/common/ActionButton';

export default {
  components: {
    RefundProduct,
    ActionCard,
    ActionButton,
  },
  props: {
    data: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      selection: [],

      displayRefundShipping: false,
      displayRefundFees: false,
      displayRefundGift: false,

      id_reason_shipping: null,
      id_reason_fees: null,
      id_reason_gift: null,

      shipping_amount: null,
      fees_amount: null,
      gift_amount: null,

      displayAjustement: false,
      isPercentage: false,
      ajustement: 0,
    };
  },
  watch: {
    data: {

      deep: true,
      immediate: true,

      async handler(id_refund) {
        id_refund = Number(id_refund);

        if (id_refund == 0) {
          await this.initRefundRequest({ order: this.order });

        } else if (id_refund) { 
          await this.fetchRefundRequest(id_refund);
        }

        this.displayRefundShipping = this.refund.shipping_amount || false;
        this.displayRefundFees = this.refund.fees_amount || false;
        this.displayRefundGift = this.refund.gift_amount || false;

        this.id_reason_shipping = this.refund.id_refund_reason_shipping;
        this.id_reason_fees = this.refund.id_refund_reason_fees;
        this.id_reason_gift = this.refund.id_refund_reason_gift;
        
        this.shipping_amount = this.refund.shipping_amount || this.order.base_shipping_incl_tax;
        this.fees_amount = this.refund.fees_amount || this.order.extension_attributes.base_fee_incl_tax;
        this.gift_amount = this.refund.gift_amount|| this.order.extension_attributes.gift_wrap_incl_tax;
      },
    },
    shippingAmount(value) {
      this.refund.shipping_amount = value;
    },
    feesAmount(value) {
      this.refund.fees_amount = value;
    },
    giftAmount(value) {
      this.refund.gift_amount = value;
    },
    grandTotal(value) {
      this.refund.grand_total = value;
    },
    id_reason_shipping(id_reason_shipping) {
      if (id_reason_shipping == 'false') {
        this.displayRefundShipping = false;
        this.id_reason_shipping = null;

      } else if (id_reason_shipping > 0)  {
        this.id_reason_shipping = id_reason_shipping;
        this.updateRefundShippingReason({ 
          id_reason_shipping: id_reason_shipping 
        });
      }
    },
    id_reason_fees(id_reason_fees) {
      if (id_reason_fees == 'false') {
        this.displayRefundFees = false;
        this.id_reason_fees = null;

      } else if (id_reason_fees > 0)  {
        this.id_reason_fees = id_reason_fees;
          this.updateRefundFeesReason({ 
            id_reason_fees: id_reason_fees 
        });
      }
    },
    id_reason_gift(id_reason_gift) {
      if (id_reason_gift == 'false') {
        console.log(id_reason_gift)
        this.displayRefundGift = false;
        this.id_reason_gift = null;
        
      } else if (id_reason_gift > 0)  {
        this.id_reason_gift = id_reason_gift;
          this.updateRefundGiftReason({ 
            id_reason_gift: id_reason_gift 
        });
      }
    },
    ajustement() {
      this.applyAjustement();
    },
    isPercentage() {
      this.applyAjustement();
    },
  },
  computed: {
    ...mapGetters('cookie', [
      'cookie'
    ]),
    ...mapGetters('order', [
      'order'
    ]),
    ...mapGetters('refund', [
      'refund', 
      'loading', 
      'error'
    ]),
    ...mapGetters('states', [
      'states'
    ]),
    displayCreateRefundRequestButton() {
      return this.refund.id_last_state == 0 
        && this.grandTotal > 0;
    },
    displayUpdateRefundRequestButton() {
      return this.refund.id_last_state == 1
        && this.grandTotal > 0;
    },
    displayCancelRefundButton() {
      return this.refund.id_last_state == 1 
        && this.grandTotal > 0;
    },
    displayValidateRefundButton() {
      return this.refund.id_last_state == 1 
        && this.grandTotal > 0;
    },
    shippingAmount: {
      get() {
        return this.shipping_amount;
      },
      set(value) {
        this.shipping_amount = parseFloat(value) || 0;
      }
    },
    feesAmount: {
      get() {
        return this.fees_amount;
      },
      set(value) {
        this.fees_amount = parseFloat(value) || 0;
      }
    },
    giftAmount: {
      get() {
        return this.gift_amount;
      },
      set(value) {
        this.gift_amount = parseFloat(value) || 0;
      }
    },
    grandTotal() {
      let products = (this.refund.products || []).reduce((total, product) => {
        const amount = product.product_amount || 0;
        const quantity = product.qty || 1;
        const discount = product.discount_amount || 0;
        return total + ((amount - discount ) * quantity) ;
      }, 0);

      const shippings = this.id_reason_shipping ? this.shippingAmount : 0;
      const fees = this.id_reason_fees ? this.feesAmount : 0;
      const gift = this.id_reason_gift ? this.giftAmount : 0;


      if (this.isPercentage) {
        products = this.round(products * (1 + this.ajustement / 100), 2);
      } else {
        products = this.round(products + this.ajustement, 2);
      }
      
      let total = this.round(products + shippings + fees + gift, 2);

      return total;
    },
  },
  methods: {
    ...mapActions('order', [
      'fetchOrder',
      'setWorkplan',
    ]),
    ...mapActions('refund', [
      'fetchRefundRequest', 
      'initRefundRequest', 
      'createRefundRequest',
      'updateRefundRequest',
      'updateRefundShippingReason',
      'updateRefundFeesReason',
      'updateRefundGiftReason',
      'addProduct',
      'cancelRefundRequest',
      'createCreditMemo',
      'validateRefundRequest',
      'clearRefundRequest',
    ]),
    ...mapActions('states', [
      'fetchRefundStates',
    ]),
    round,
    formatDate,
    capitalizeEachWord, 
    horizontalScroll,
    closeWorkplan() {
      this.clearRefundRequest();
      this.$emit('close-workplan');
    },
    displayRefund(refund) {
      switch (refund) {
        case 'shipping': this.displayRefundShipping = true; break;
        case 'fees': this.displayRefundFees = true; break;
        case 'gift': this.displayRefundGift = true; break;
      }
    },
    validateNumber(event) {
      const value = event.target.value;
      if (!/^\d*\.?\d*$/.test(value)) {
        event.target.value = this.giftAmount || '';
      }
    },
    applyAjustement() {
      if (this.isPercentage) {
        this.grandTotal = this.grandTotal * (1 + this.ajustement / 100);
      } else {
        this.grandTotal = this.grandTotal + this.ajustement;
      }
    },
    async validRefundRequest() {
      this.validateRefundRequest();
      this.fetchOrder(this.order.entity_id);
    }
  },
  mounted() {
    if (!this.states.refunds) {
      this.fetchRefundStates();
    }
  }
}
</script>
