<template>
  
  <div class="col-12 h-100">
    <!-- div class="row mb-3">
      <label for="season" class="col-2 col-form-label">Saisonnalité</label>
      <div class="col-2">
        <input type="text" class="form-control" id="season">
      </div>
    </div -->
    <div class="row mb-3">
      <label for="threshold" class="col-2 col-form-label">Seuil</label>
      <div class="col-2">
        <input type="number" class="form-control" id="threshold" v-model.number="threshold" @keyup.enter="onUpdateThreshold">
      </div>
    </div>
    <div class="row mb-3">
      <label for="stock" class="col-2 col-form-label">Stock</label>
      <div class="col-2">
        <input type="number" class="form-control" id="stock" :value="stock" readonly disabled>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    store: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      threshold: null,
      stock: null,
    };
  },
  computed: {
    ...mapGetters('cookie', ['cookie']),
    ...mapGetters('product', ['product']),
    currentStoreStocks() {
      return this.product?.stocks?.find(stock => stock?.source_code == this.store?.source_code) || null;
    },
  },
  watch: {
    store: {
      immediate: true,
      handler() {
        this.updateLocalValues();
      },
    },
    product: {
      immediate: true,
      handler() {
        this.updateLocalValues(); 
      },
    },
  },
  methods: {
    ...mapActions('product', ['fetchProductStocks', 'updateThreshold']),
    onUpdateThreshold() {
      this.updateThreshold({
        sku: this.product.sku,
        id_stock_source: this.store.id_stock_source,
        threshold: this.threshold,
        source_code: this.cookie.user.store.source_code,
        id_user: this.cookie.user.id_user,
      });

      const storeStock = this.product.stocks.find(
        stock => stock.source_code == this.store.source_code
      );

      if (storeStock) {
        storeStock.threshold = this.threshold;
      }
    },
    updateLocalValues() {
      if (this.currentStoreStocks) {
        this.threshold = this.currentStoreStocks.threshold;
        this.stock = this.currentStoreStocks.quantity;
      }
    },
  },
  mounted() {
    this.fetchProductStocks();
    this.updateLocalValues();
  },
};
</script>
