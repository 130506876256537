import returnService from '@/services/returnService';

export default {
  namespaced: true,
  state: {
    loading: false,
    error: null,
    rma: {},
  },
  mutations: {
    SET_RETURN(state, rma) {
      state.rma = rma;
    },
    SET_RETURN_STATES(state, states) {
      state.rma = { ...state.rma, states };
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    CLEAR_ERROR(state) {
      state.error = null;
    },
    CLEAR_RETURN(state) {
      state.rma = {
        request_items: [],
      };
    },
  },
  actions: {
    async fetchReturn({ commit }, return_id) {
      try {
        commit('CLEAR_RETURN');
        commit('SET_LOADING', true);
        commit('SET_RETURN', await returnService.getReturn(return_id));
        commit('SET_RETURN_STATES', await returnService.getStates());
        commit('SET_LOADING', false);
      } catch (error) {
        console.error(error);
      }
    },
    async initReturn({ state, commit }, { order, items }) {
      try {

        commit('CLEAR_RETURN');
        commit('SET_RETURN', {
          customer_id: order.customer_id || null,
          order_id: order.entity_id,
          store_id: order.store_id,
          status: 0,
          customer_name: order.customer_firstname,
          request_items: [],
        });

        if (items) {
          items.forEach((item) => {
            if (!item.parent_item) {
              state.rma.request_items.push({
                order_item_id: item.item_id,
                qty: item.qty_shipped,
                sku: item.sku,
                reason_id: 0,
                condition_id: 0,
                resolution_id: 0,
                item_status: 0,
                new: true,
              });
            }
          });
        }
        

        commit('SET_RETURN_STATES', await returnService.getStates());

      } catch (error) {
        console.error(error);
      }
    },

    async createReturn({ dispatch, commit, rootState: { order: { order }}}, request) {
      try { commit('SET_LOADING', true);

        let request_items = [];

        for (let product of request.request_items) {
          if (!product.reason_id || !product.condition_id) {
            dispatch('alerts/setAlert', 
              { style: 'alert-warning', message: 'Choisissez une raison et un état pour chaque produit' }, 
              { root: true }
            ); 
            commit('SET_LOADING', false);
            return;
          }

          request_items.push({
            id_refund_reason: product.id_refund_reason,
            order_item_id: product.order_item_id,
            qty: product.qty,
            reason_id: product.reason_id,
            condition_id: product.condition_id,
            resolution_id: product.resolution_id,
            item_status: 0,
          });
        }

        delete request.states;
        const response = await returnService.createReturn(order.entity_id, {
          request: {
          ...request,
          request_items: request_items,
        }});

        dispatch('order/fetchOrderReturns', order.entity_id, { root: true });

        if (response && response.success) {
          dispatch('fetchReturn', response.success.results.request_id)
          dispatch('alerts/setAlert', 
            { style: 'alert-success', message: response.success.message }, 
            { root: true }
          );
        }
      } catch (error) {
        commit('SET_ERROR', error.message);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async addProduct({ state, dispatch }, event) {

      const items = (event.items && event.items.length > 0) ? event.items : [event.item];
      const indexes = (event.newIndicies && event.newIndicies.length > 0) ? event.newIndicies : [{ index: event.newIndex }];

      items.forEach(() => {

        // Vérification de l'état de la demande de retour
        if (state.rma.status > 1) {
          dispatch('alerts/setAlert', 
            { style: 'alert-danger', message: 'Cette demande de retour est cloturée, formulez une nouvelle demande de retour.' }, 
            { root: true }
          );
          state.rma.request_items.splice(indexes[0].index, 1);
          return;
        }

        const item = state.rma.request_items[indexes[0].index];
        state.rma.request_items.splice(indexes[0].index, 1);

        state.rma.request_items.push({
          order_item_id: item.item_id,
          qty: item.qty_shipped,
          sku: item.sku,
          reason_id: 0,
          condition_id: 0,
          resolution_id: 0,
          item_status: 0,
          new: true,
        });

      });
    },
    async updateProductReason({ state }, { order_item_id, reason_id}) {
      state.rma.request_items.find(product => product.order_item_id == order_item_id).reason_id = reason_id;
    },
    async updateProductCondition({ state }, { order_item_id, condition_id}) {
      state.rma.request_items.find(product => product.order_item_id == order_item_id).condition_id = condition_id;
    },
    async updateProductResolution({ state }, { order_item_id, resolution_id}) {
      state.rma.request_items.find(product => product.order_item_id == order_item_id).resolution_id = resolution_id;
    },
    async updateProductStatus({ state }, { order_item_id, item_status }) {
      state.rma.request_items.find(product => product.order_item_id == order_item_id).item_status = item_status;
    },
    async generateLabels({ state, dispatch, commit, rootState: { order: { order } }}) {
      try { commit('SET_LOADING', true);

        const response = await returnService.createLabels(state.rma.request_id, {
          order: {
            entity_id: order.entity_id,
            increment_id: order.increment_id,
            customer_email: order.customer_email,
            customer_id: order.customer_id || 0,
            extension_attributes: order.extension_attributes,
          },
          address: order.shipping_address
        });

        dispatch('order/fetchOrderReturns', order.entity_id, { root: true });
        dispatch('fetchReturn', state.rma.request_id);

        if (response && response.success) {
          dispatch('alerts/setAlert', 
            { style: 'alert-success', message: response.success.message }, 
            { root: true }
          );
        }

      } catch (error) {
        console.error(error);
      }
    },
    async cancelReturn({ state, commit, dispatch, rootState: { order: { order }}}) {
      if (confirm('Etes-vous sûr de vouloir annuler cette demande de retour ?')) {
        try { commit('SET_LOADING', true);

          const response = await returnService.cancelReturn(
            state.rma.request_id, { 
              request: { 
                status: 13
              }
            });

          dispatch('order/setWorkplan', { component: null, element: null }, { root: true });
          dispatch('order/fetchOrderReturns', order.entity_id, { root: true });

          if (response && response.success) {
            dispatch('alerts/setAlert', 
              { style: 'alert-success', message: 'Demande de retour annulée avec succès' }, 
              { root: true }
            );
          }

          commit('CLEAR_RETURN');
        } catch (error) {
          commit('SET_ERROR', error.message);
        } finally {
          commit('SET_LOADING', false);
        }
      }
    },
    async approveReturn({ state, commit, dispatch, rootState: { order: { order }}}) {
      const notify = confirm('Voulez-vous prévenir le client par e-email ?');
      
      try { commit('SET_LOADING', true);

        const response = await returnService.approveReturn(
          state.rma.request_id, {
            notify: notify,
            order: order,
            products: state.rma.request_items,
            request: state.rma,
          });

        dispatch('order/setWorkplan', { component: null, element: null }, { root: true });
        dispatch('order/fetchOrderReturns', order.entity_id, { root: true });

        if (response && response.success) {
          dispatch('fetchReturn', response.success.results.request_id)
          dispatch('alerts/setAlert', 
            { style: 'alert-success', message: notify ? 'Client notifié avec succès' : 'Demande de retour mise à jour avec succès' }, 
            { root: true }
          );
        }

      } catch (error) {
        commit('SET_ERROR', error.message);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async refuseReturn({ state, commit, dispatch, rootState: { order: { order }}}) {
      const notify = confirm('Voulez-vous prévenir le client par e-email ?');
      
      try { commit('SET_LOADING', true);

        const response = await returnService.refuseReturn(
          state.rma.request_id, {
            notify: notify,
            order: order,
            products: state.rma.request_items,
            request: state.rma,
          });

        dispatch('order/setWorkplan', { component: null, element: null }, { root: true });
        dispatch('order/fetchOrderReturns', order.entity_id, { root: true });

        if (response && response.success) {
          dispatch('fetchReturn', response.success.results.request_id)
          dispatch('alerts/setAlert', 
            { style: 'alert-success', message: notify ? 'Client notifié avec succès' : 'Demande de retour mise à jour avec succès' }, 
            { root: true }
          );
        }

      } catch (error) {
        commit('SET_ERROR', error.message);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async completeReturn({ state, commit, dispatch, rootState: { order: { order }}}) {
      const notify = confirm('Voulez-vous prévenir le client par e-email ?');
      
      try { commit('SET_LOADING', true);

        const response = await returnService.completeReturn(
          state.rma.request_id, {
            notify: notify,
            order: order,
            products: state.rma.request_items,
            request: state.rma,
          });

        dispatch('order/setWorkplan', { component: null, element: null }, { root: true });
        dispatch('order/fetchOrderReturns', order.entity_id, { root: true });

        if (response && response.success) {
          dispatch('fetchReturn', response.success.results.request_id)
          dispatch('alerts/setAlert', 
            { style: 'alert-success', message: notify ? 'Client notifié avec succès' : 'Demande de retour terminée avec succès' }, 
            { root: true }
          );
        }

      } catch (error) {
        commit('SET_ERROR', error.message);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async clearReturn({ commit }) {
      commit('CLEAR_RETURN');
    },
  }, 
  getters: {
    rma: state => state.rma,
    loading: state => state.loading,
    error: state => state.error,
  },
};
 