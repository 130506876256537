<template>
  <div class="col d-flex flex-column h-100">
    <div class="row flex-grow-1 justify-content-center overflow-auto">
      
      <template v-if="error">{{ error }}</template>
      <template v-else-if="loading"><loader/></template>
      <template v-else>
        
        <draggable
          class="row row-cols-3 flex-grow-1 overflow-y-auto justify-content-center align-items-center align-content-center"
          :list="rma.request_items"
          :group="{ name: 'return-products', pull: false, put: ['products'] }"
          :multi-drag="false"
          selected-class="selected"
          :sort="false"
          @add="addProduct" >

          <template v-slot:item="{ element, index }">
            <article v-if="element.sku" class="col-12 m-1" :itemKey="index" :sku="element.sku">
              <return-product :product="element" />
            </article>
          </template>

        </draggable>
      </template>
    </div>

    <div class="row align-items-center border-top py-1 ">
      <div class="col-auto">
        <span v-if="rma.request_id"><strong>Retour #{{ rma.request_id }}</strong> créé le {{ formatDate(rma.created_at).slash }} à {{ formatDate(rma.created_at).time }}</span>
        <span v-else ><strong>Nouveau retour</strong></span>
      </div>
      <div class="col-auto ms-auto">
        <button class="btn btn-sm btn-danger" @click="closeWorkplan">Fermer</button>
      </div>
    </div>

    <div class="row border-top py-3 bg-light">
      <div class="col-12">

        <div class="row h-128 flex-nowrap overflow-x-auto" @wheel="horizontalScroll">

          <div class="col-auto me-auto">
            <div class="row h-100">
          
              <action-card v-if="rma.tracking_numbers && rma.tracking_numbers.length > 0" 
                :title="'Numéro de suivi'">
                <template #content>
                  <div class="row d-flex flex-column g-1">
                    <div v-for="(tracking, index) in rma.tracking_numbers" :key="index">
                      <strong>{{ capitalizeEachWord(tracking.tracking_code) }}</strong> :<br>{{ tracking.tracking_number }}
                    </div>
                  </div>
                </template>
              </action-card>

              <action-card v-else-if="rma.request_id" 
              :title="'Numéro de suivi'" >
                <template #content>
                  <button class="btn btn-primary" @click="generateLabels">Générer bordereaux</button>
                </template>
              </action-card>
          
            </div>
          </div>

          <div class="col-auto">
            <div class="row h-100">

              <action-button v-if="displayCreateReturnButton" :icon="'bi bi-cloud-upload'" :text="'Créer\nun retour'" :color="'white'" :type="'primary'" @click="createReturn(rma)" />
              <action-button v-if="displayCancelReturnButton" :icon="'bi-x-circle'" :text="'Annuler\nla demande'" :color="'white'" :type="'danger'" @click="cancelReturn(refund)" />

              <!-- <action-button v-if="displayUpdateReturnButton" :icon="'bi bi-cloud-upload'" :text="'Mettre à jour\nle retour'" :color="'white'" :type="'primary'" @click="updateReturn(rma)" /> -->

              <action-button v-if="displayRefuseReturnButton" :icon="'bi-dash-circle'" :text="'Refuser\nla demande'" :color="'white'" :type="'danger'" @click="refuseReturn(refund)" />
              <action-button v-if="displayApproveReturnButton" :icon="'bi-check-circle'" :text="rma.request_items.every(item => item.item_status == 1) ? 'Valider\nla demande' : 'Valider\npartiellement'" :color="'white'" :type="'success'" @click="approveReturn(refund)" />

              <action-button v-if="displayCompleteReturnButton" :icon="'bi-check-circle'" :text="'Terminer\nle retour'" :color="'white'" :type="'success'" @click="completeReturn(refund)" />

            </div>
          </div>

        </div>

      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatDate, capitalizeEachWord, horizontalScroll } from '@/utils/tools';
import ReturnProduct from '@/components/order/OrderReturnProduct';
import ActionCard from '@/components/common/ActionCard';
import ActionButton from '@/components/common/ActionButton';

export default {
  props: {
    data: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      selection: [],
    };
  },
  components: {
    ReturnProduct,
    ActionCard,
    ActionButton,
  },
  computed: {
    ...mapGetters('order', [
      'order'
    ]),
    ...mapGetters('rma', [
      'rma',
      'loading', 
      'error'
    ]),
    displayCreateReturnButton() {
      return this.rma.status == 0 
        && this.rma.request_items.length > 0;
    },
    displayUpdateReturnButton() {
      return this.rma.status == 1 
        && this.rma.request_items.length > 0;
    },
    displayCancelReturnButton() {
      return this.rma.status == 1 
        && this.rma.request_items.length > 0;
    },
    displayRefuseReturnButton() {
      return this.rma.status == 1 
        && this.rma.request_items.length > 0;
    },
    displayApproveReturnButton() {
      return this.rma.status == 1 
        && this.rma.request_items.length > 0;
    },
    displayCompleteReturnButton() {
      return this.rma.status == 4
        && this.rma.request_items.length > 0 
        && this.rma.request_items.every(item => item.item_status > 0);
    },
  },
  watch: {
    data: {
      async handler(rma) {

        if (rma == 'initReturnRequest') {
          this.initReturn({ order: this.order });

        } else if (rma.request_id) { 
          await this.fetchReturn(rma.request_id);
          await this.fetchReturnProducts();
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    ...mapActions('rma', [
      'fetchReturn', 
      'initReturn', 
      'createReturn',
      'addProduct',
      'generateLabels',
      'updateReturn',
      'cancelReturn',
      'approveReturn',
      'refuseReturn',
      'completeReturn',
      'clearReturn',
    ]),
    formatDate,
    capitalizeEachWord,
    horizontalScroll,
    findProductByOrderItemId(order_item_id) {
      return this.order.items.find(item => item.item_id == order_item_id);
    },
    async fetchReturnProducts() {
      this.rma.request_items = this.rma.request_items.map(item => {
        const order_product = this.findProductByOrderItemId(item.order_item_id);
        if (order_product) {
          return { ...item, ...order_product };
        } else return item;
      });
    },
    closeWorkplan() {
      this.clearReturn();
      this.$emit('close-workplan');
    },
  },
}
</script>
