import refundService from '@/services/refundService';

export default {
  namespaced: true,
  state: {
    loading: false,
    error: null,
    refund: {
      products: [],
    },
  },
  mutations: {
    SET_REFUND(state, refund) {
      state.refund = refund;
    },
    SET_REFUND_PRODUCTS(state, products) {
      state.refund = { ...state.refund, products };
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    CLEAR_ERROR(state) {
      state.error = null;
    },
    CLEAR_REFUND(state) {
      state.loading = false,
      state.error = null;
      state.refund = {};
    },
  },
  actions: {
    async fetchRefundRequest({ commit }, id_refund) {
      try {
        commit('SET_LOADING', true);
        commit('SET_REFUND', await refundService.getRefundRequest(id_refund));
        commit('SET_REFUND_PRODUCTS', await refundService.getRefundRequestProducts(id_refund));
        commit('SET_LOADING', false);
      } catch (error) {
        console.error(error);
      }
    },
    async initRefundRequest({ state, commit }, { order, items }) {
      try {

        let shipping_tax = order.extension_attributes.item_applied_taxes.find(item => item.type == 'shipping') || null;

        commit('CLEAR_REFUND');
        commit('SET_REFUND', {
          store_id: order.store_id,
          order_id: order.entity_id,
          increment_id: order.increment_id,
          id_last_state: 0,
          shipping_amount: 0,
          fees_amount: 0,
          gift_amount: 0,
          grand_total: 0,
          shipping_tax_rate: shipping_tax ? shipping_tax.applied_taxes[0].percent || 0 : 0,
          shipping_tax_amount: order.base_shipping_tax_amount ? order.base_shipping_tax_amount : 0,
          payment_method: order.payment.method,
          products: [],
        });

        if (items) {
          items.forEach((item) => {
            if (!item.parent_item) {
              state.refund.products.push({
                id_refund_reason: 0,
                order_item_id: item.item_id,
                product_amount: item.base_price_incl_tax,
                disocunt_amount : item.base_discount_amount,
                qty: item.qty_invoiced,
                sku: item.sku,
                new: true,
              });
            }
          });
        }

      } catch (error) {
        console.error(error);
      }
    },
    async createRefundRequest({ commit, dispatch, rootState: { order: { order }}}, request) {
      try { commit('SET_LOADING', true);
    
        let products = [];
        let products_amount = 0;

        for (let product of request.products) {
          if (!product.id_refund_reason) {
            dispatch('alerts/setAlert', 
              { style: 'alert-warning', message: 'Choisissez un motif de remboursement pour chaque produit' }, 
              { root: true }
            ); 
            commit('SET_LOADING', false);
            return;
          } 
    
          products.push({
            id_refund_reason: product.id_refund_reason,
            order_item_id: product.order_item_id,
            sku: product.sku,
            product_amount: product.product_amount,
            discount_amount: product.discount_amount,
            qty: product.qty,
          });

          products_amount += product.product_amount;
        }
        
        const response = await refundService.createRefundRequest(order.entity_id, {
          store_id: request.store_id,
          order_id: request.order_id,
          increment_id: request.increment_id,
          grand_total: request.grand_total,
          products_amount: products_amount,
          id_refund_reason_shipping: request.id_refund_reason_shipping || 0,
          shipping_amount: request.shipping_amount || 0,
          id_refund_reason_fees: request.id_refund_reason_fees || 0,
          fees_amount: request.fees_amount || 0,
          id_refund_reason_gift: request.id_refund_reason_gift || 0,
          gift_amount: request.gift_amount || 0,
          shipping_tax_rate: request.shipping_tax_rate || 0,
          shipping_tax_amount: request.shipping_tax_amount > 0 ? request.shipping_tax_amount : order.base_shipping_tax_amount || 0,
          payment_method: request.payment_method,
          products: products,
        });
    
        dispatch('order/fetchOrderRefunds', order.entity_id, { root: true });
    
        if (response && response.success) {
          dispatch('alerts/setAlert', 
            { style: 'alert-success', message: response.success.message }, 
            { root: true }
          );
        }
        
        commit('CLEAR_REFUND');
        commit('SET_REFUND', request);
      } catch (error) {
        commit('SET_ERROR', error.message);
      }
    },
    async updateRefundRequest({ state, commit, dispatch, rootState: { order: { order }}}, request) {
      try { commit('SET_LOADING', true);
    
        let products = [];
        let products_amount = 0;

        for (let product of request.products) {
          if (!product.id_refund_reason) {
            dispatch('alerts/setAlert', 
              { style: 'alert-warning', message: 'Choisissez un motif de remboursement pour chaque produit' }, 
              { root: true }
            ); 
            commit('SET_LOADING', false);
            return;
          } 
    
          products.push({
            id_refund_product: product.id_refund_product || 0,
            id_refund_reason: product.id_refund_reason,
            order_item_id: product.order_item_id,
            sku: product.sku,
            product_amount: product.product_amount,
            discount_amount: product.discount_amount,
            qty: product.qty,
          });

          products_amount += product.product_amount;
        }
    
        const response = await refundService.updateRefundRequest(state.refund.id_refund, {
          grand_total: request.grand_total,
          products_amount: products_amount,
          id_refund_reason_shipping: request.id_refund_reason_shipping || 0,
          shipping_amount: request.shipping_amount || 0,
          id_refund_reason_fees: request.id_refund_reason_fees || 0,
          fees_amount: request.fees_amount || 0,
          id_refund_reason_gift: request.id_refund_reason_gift || 0,
          gift_amount: request.gift_amount || 0,
          shipping_tax_rate: request.shipping_tax_rate || 0,
          shipping_tax_amount: request.shipping_tax_amount > 0 ? request.shipping_tax_amount : order.base_shipping_tax_amount || 0,
          products: products,
        });
    
        dispatch('order/fetchOrderRefunds', order.entity_id, { root: true });
    
        if (response && response.success) {
          dispatch('alerts/setAlert', 
            { style: 'alert-success', message: response.success.message }, 
            { root: true }
          );
        }
        
        commit('CLEAR_REFUND');
        commit('SET_REFUND', request);
      } catch (error) {
        commit('SET_ERROR', error.message);
      } finally {
        commit('SET_LOADING', false);
      }
    },
    async cancelRefundRequest({ state, commit, dispatch, rootState: { order: { order }}}) {
      if (confirm('Etes-vous sûr de vouloir annuler cette demande de remboursmeent ?')) {
        try { commit('SET_LOADING', true);
      
          const response = await refundService.cancelRefundRequest(state.refund.id_refund);
          if (response && response.success) {
            dispatch('alerts/setAlert', 
              { style: 'alert-success', message: response.success.message }, 
              { root: true }
            );
          }
          dispatch('order/setWorkplan', { component: null, element: null }, { root: true });
          dispatch('order/fetchOrderRefunds', order.entity_id, { root: true });

          commit('CLEAR_REFUND');
        } catch (error) {
          commit('SET_ERROR', error.message);
        } finally {
          commit('SET_LOADING', false);
        }
      }
    },
    async addProduct({ state, dispatch }, event) {

      const items = (event.items && event.items.length > 0) ? event.items : [event.item];
      const indexes = (event.newIndicies && event.newIndicies.length > 0) ? event.newIndicies : [{ index: event.newIndex }];

      items.forEach(() => {

        // Vérification de l'état de la demande de remboursement
        if (state.refund.id_last_state > 1) {
          dispatch('alerts/setAlert', 
            { style: 'alert-danger', message: 'Cette demande de remboursement est cloturée, formulez une nouvelle demande de remboursement.' }, 
            { root: true }
          );
          state.refund.products.splice(indexes[0].index, 1);
          return;
        }

        let item = state.refund.products[indexes[0].index];
        state.refund.products.splice(indexes[0].index, 1);

        if (item.parent_item) {
          item = item.parent_item;
        }

        const product = {
          id_refund_reason: 0,
          order_item_id: item.item_id,
          product_amount: item.base_price_incl_tax,
          discount_amount: item.base_discount_amount,
          qty: item.qty_invoiced,
          sku: item.sku,
          new: true,
        }

        state.refund.products.push(product);
      });
    },
    async updateRefundShippingReason({ state }, { id_reason_shipping }) {
      state.refund.id_refund_reason_shipping = id_reason_shipping;
    },
    async updateRefundFeesReason({ state }, { id_reason_fees }) {
      state.refund.id_refund_reason_fees = id_reason_fees;
    },
    async updateRefundGiftReason({ state }, { id_reason_gift }) {
      state.refund.id_refund_reason_gift = id_reason_gift;
    },
    async updateProductRefundReason({ state }, { sku, id_refund_reason}) {
      const product = state.refund.products.find(product => product.sku == sku);
      product.id_refund_reason = id_refund_reason;
    },
    async updateProductRefundQty({ state }, { sku, qty }) {
      const product = state.refund.products.find(product => product.sku == sku);
      product.qty = qty;
    },
    async removeProduct({ state }, sku) {
      const index = state.refund.products.findIndex(product => product.sku == sku);
      if (index != -1) {
        state.refund.products.splice(index, 1);
      }
    },
    async createCreditMemo({ state, commit, dispatch, rootState: { order: { order }}}) {
      if (confirm('Etes-vous sûr de vouloir effectuer le remboursement ?')) {
        try { commit('SET_LOADING', true);

          if (state.refund.products.length == 0) {
            order.items.forEach(item => {
              if (!item.parent_item) {
                const product = {
                  order_item_id: item.item_id,
                  qty: 0,
                }
                state.refund.products.push(product);
              }
            });
          }
      
          const response = await refundService.createCreditMemo(order.entity_id, state.refund);
          if (response && response.success) {
            dispatch('validateRefundRequest');
            dispatch('alerts/setAlert', 
              { style: 'alert-success', message: response.success.message }, 
              { root: true }
            );
          }
          dispatch('order/fetchOrder', order.entity_id, { root: true });
          dispatch('order/setWorkplan', { component: null, element: null }, { root: true });

          commit('CLEAR_REFUND');
        } catch (error) {
          commit('SET_ERROR', error.message);
        } finally {
          commit('SET_LOADING', false);
        }
      }
    },
    async validateRefundRequest({ state }) {
      await refundService.validateRefundRequest(state.refund.id_refund);
    },
    async clearRefundRequest({ commit }) {
      commit('CLEAR_REFUND');
    },
  }, 
  getters: {
    refund: state => state.refund,
    loading: state => state.loading,
    error: state => state.error,
  },
};
