<template>

  <div v-if="data" class="row align-items-center border">

    <div class="col-1">
      <img v-if="productData.media_gallery_entries && productData.media_gallery_entries.length > 0" class="img-fluid" :src="productImageUrl(productData.media_gallery_entries[0].file)" alt="Product Image">
      <img v-else class="img-fluid" :src="productImageUrl('/placeholder/websites/1/img_placeholder_base.jpg')" alt="Product Image">
    </div>

    <div class="col d-flex align-items-center fw-bold lh-sm">
      {{ productData.name }}
    </div>

    <div v-if="states.refunds" class="col d-flex flex-column lign-items-center fw-bold lh-sm">
      <select v-model="id_refund_reason" class="form-select form-select-sm" required :disabled="refund.id_last_state > 1">
        <option value="0" disabled>Séléctionnez le motif du rembours.</option>
        <option v-for="(reason, index) in states.refunds.reasons.products" :key="index" :value="reason.id_refund_reason">
          {{ reason.name }}
        </option>
      </select>
    </div>

    <div class="col-1 square-64 d-flex flex-column justify-content-center fw-bold">
      <input v-model="quantity" type="text" class="form-control form-control-sm text-center" :disabled="refund.id_last_state > 1" />
    </div>

    <div v-if="productData.new" class="col-1 square-64 d-flex flex-column justify-content-center fw-bold">
      <i class="bi bi-x fs-3 text-danger" role="button" @click="removeProduct(productData.sku)"></i>
    </div>

  </div>

  <div v-else class="row h-64 align-items-center border">
    <div class="col-12">
      <loader/>
    </div>
  </div>

</template>


<script>
import { mapGetters, mapActions } from 'vuex';
import { productImageUrl } from '@/utils/tools';

export default {
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: null,
      id_refund_reason: null,
      quantity: null,
    };
  },
  watch: {
    product: {
      deep: true,
      immediate: true,
      handler() {

        this.data = this.order.items.find(item => item.item_id == this.product.order_item_id);
        this.id_refund_reason = this.product.id_refund_reason;
        this.quantity = this.product.qty;
      }
    },
    id_refund_reason(id_refund_reason) {
      this.updateProductRefundReason({ 
        sku: this.productData.sku, 
        id_refund_reason: id_refund_reason 
      });
    },
    quantity(qty) {
      this.updateProductRefundQty({ 
        sku: this.productData.sku, 
        qty: qty 
      });
    },
  },
  computed: {
    ...mapGetters('order', ['order']),
    ...mapGetters('refund', ['refund']),
    ...mapGetters('states', ['states']),
    productData() {
      return {
        ...this.data,
        ...this.product,
      };
    }
  },
  methods: {
    ...mapActions('refund', [
      'updateProductRefundReason',
      'updateProductRefundQty',
      'removeProduct',
    ]),
    productImageUrl,
  },
}
</script>
